// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-acerca-js": () => import("../src/pages/acerca.js" /* webpackChunkName: "component---src-pages-acerca-js" */),
  "component---src-pages-analisis-falla-js": () => import("../src/pages/analisis_falla.js" /* webpackChunkName: "component---src-pages-analisis-falla-js" */),
  "component---src-pages-certificacion-abierto-js": () => import("../src/pages/certificacion_abierto.js" /* webpackChunkName: "component---src-pages-certificacion-abierto-js" */),
  "component---src-pages-certificacion-aceros-js": () => import("../src/pages/certificacion_aceros.js" /* webpackChunkName: "component---src-pages-certificacion-aceros-js" */),
  "component---src-pages-certificacion-tubular-js": () => import("../src/pages/certificacion_tubular.js" /* webpackChunkName: "component---src-pages-certificacion-tubular-js" */),
  "component---src-pages-contacto-js": () => import("../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-ensayos-laboratorio-js": () => import("../src/pages/ensayos_laboratorio.js" /* webpackChunkName: "component---src-pages-ensayos-laboratorio-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-informes-js": () => import("../src/pages/informes.js" /* webpackChunkName: "component---src-pages-informes-js" */),
  "component---src-pages-ingenieria-inversa-js": () => import("../src/pages/ingenieria_inversa.js" /* webpackChunkName: "component---src-pages-ingenieria-inversa-js" */),
  "component---src-pages-visita-terreno-js": () => import("../src/pages/visita_terreno.js" /* webpackChunkName: "component---src-pages-visita-terreno-js" */)
}

